<template lang="pug">
  Auth(@loggedIn="onLoggedIn")
    Header(:content="headerContent")
    NowLoading(:show="showNowLoading")
    div.wrap-send-events.f.fh
      ModuleSendEvents(ref="send_events")

</template>

<style lang="scss" scoped>
.wrap-send-events {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { formatRFC3339 } from 'date-fns'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import Header from '@/components/shared/Header.vue'
import NowLoading from '@/components/shared/NowLoading'
import ModuleSendEvents from '@/components/module/ModuleSendEvents'

export default {
  components: {
    Auth,
    Header,
    NowLoading,
    ModuleSendEvents
  },
  data() {
    return {
      headerContent: {
        title: '参加可能な日時を選択',
        // left: {
        //   label: 'Home',
        //   icon: 'mdi-home',
        //   to: '/tmp'
        // },
        right: {
          label: 'OK',
          color: '#4285f4',
          action: this.toSendEventsComplete
        }
      },
      showNowLoading: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    onLoggedIn() {},
    async toSendEventsComplete() {
      const go = confirm('参加可能な日時を送信します')
      if (!go) return

      this.showNowLoading = true

      const setCondition = this.$refs.send_events

      // 選択されなかったcandidateSlotsをeventにする
      const events = setCondition.availableSlots
        .filter((slot) => !slot.checked)
        .map((slot) => {
          return {
            start: formatRFC3339(slot.start.toDate()),
            end: formatRFC3339(slot.end.toDate())
          }
        })

      const attendeeEvents = {
        createdBy: this.uid,
        email: this.$route.params.inviteeEmail,
        authEmail: 'anonymous',
        timeZone: Intl
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : { offset: new Date().getTimezoneOffset() },
        createdAt: new Date(),
        events
      }

      await database
        .meetingCollection()
        .setAttendeeEvents(
          this.$route.params.meetingId,
          this.$route.params.inviteeEmail,
          attendeeEvents
        )

      this.showNowLoading = false
      this.$router.push(
        `/send-events-complete/${this.$route.params.meetingId}/${this.$route.params.inviteeEmail}`
      )
    }
  }
}
</script>
