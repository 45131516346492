<template lang="pug">
  div.wrap-module-envents
    div(v-if="meeting").envents-content
      div.wrap-title.pt10.pb12
        span.block.fz18.bold.mb3 {{meeting.title}}
        span.block.fz14 {{meeting.about}}
      div.wrap-candidate-slots.py8
        div(v-for="(item, index) in availableSlots").check-box
          span(v-if="(index == 0) || availableSlots[index - 1] && (availableSlots[index - 1].dayLabel != item.dayLabel)").day-label.block.mt8.mb2.fz14 {{item.dayLabel}}
          v-checkbox(v-model="item.checked" :label="item.label")
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-envents {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .envents-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .wrap-title {
      border-bottom: solid $border_size $border_color;
    }
    .day-label {
      color: $secondary_text_color;
    }
  }
}
</style>

<style lang="scss">
.wrap-module-envents {
  .v-input--checkbox {
    margin: 0 !important;
  }
  .v-messages {
    display: none;
  }
}
</style>

<script>
import format from 'date-fns/format'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      meeting: null,
      availableSlots: null
    }
  },
  async created () {
    this.meeting = await database.meetingCollection()
      .findById(this.$route.params.meetingId)
    console.log('meeting', this.meeting)

    this.availableSlots = this.meeting.candidateSlots.map(slot => {
      return {
        label: `${format(slot.start.toDate(), 'HH:mm')} ~ ${format(slot.end.toDate(), 'HH:mm')}`,
        dayLabel: `${format(slot.start.toDate(), 'yyyy/MM/dd')}`,
        start: slot.start,
        end: slot.end,
        checked: false,
      }
    })
  },
  methods: {
    
  }
}
</script>
